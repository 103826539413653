import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../Common/Loader';
import DashboardLayouts from '../Layouts/DashboardLayouts';
import config from '../../config';

const ListEquipmentTowerDetails = () => {
    const location = useLocation();
    const { state } = location;
    const item = state?.item;
    const [isLoading, setIsLoading] = useState(false);
    const [selected1Option, setSelected1Option] = useState("");
    const [selected2Option, setSelected2Option] = useState("");
    const [selected3Option, setSelected3Option] = useState("");
    const [selected4Option, setSelected4Option] = useState("");
    const [selected5Option, setSelected5Option] = useState("");
    const [towerheight, setTowerHeight] = useState(0);

    return (
        <>
            <DashboardLayouts>
                {isLoading != true && item ?
                    <>
                        <div class="row">
                            <span class="project_text_title"><b>Project:</b> {item.project_id}</span>
                            <div class="row">
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12"> <span class="small_text_title"><b>Tower ID:</b> {item.tower_id}</span></div>
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                                    <span class="small_text_title"><b>Circle:</b> {item.circle_id} </span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                <b>Please fill the following:-</b>
                            </div>
                        </div>
                        <div class="pending_information_scroll">
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th align="center" valign="middle" width="5%">1.</th>
                                            <th align="center" valign="middle" width="60%">Did you find the tower ?</th>
                                            <td align="center" valign="middle" width="25%">

                                                <select value={selected1Option} onChange={(e) => setSelected1Option(e.target.value)} className='form-control find_tower_info'>
                                                    <option value="">Select an option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr class="find_tower_info_no">
                                            <th align="center" valign="middle">2.</th>
                                            <th align="center" valign="middle">Is tower commissioned ?</th>
                                            <td align="center" valign="middle">
                                                <select value={selected2Option} onChange={(e) => setSelected2Option(e.target.value)} className='form-control find_tower_info'>
                                                    <option value="">Select an option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr class="find_tower_info_no">
                                            <th align="center" valign="middle">3.</th>
                                            <th align="center" valign="middle">Is construction is process ?</th>
                                            <td align="center" valign="middle">
                                                <select value={selected3Option} onChange={(e) => setSelected3Option(e.target.value)} className='form-control find_tower_info'>
                                                    <option value="">Select an option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr class="find_tower_info_no">
                                            <th align="center" valign="middle">4.</th>
                                            <th align="center" valign="middle">Tower height (meter)</th>
                                            <td align="center" valign="middle">
                                                <input type="text" class="form-control" value="0" />
                                            </td>
                                        </tr>
                                        <tr class="find_tower_info_no">
                                            <th align="center" valign="middle">5.</th>
                                            <th align="center" valign="middle">Is tower up &amp; running ?</th>
                                            <td align="center" valign="middle">
                                                <select value={selected4Option} onChange={(e) => setSelected4Option(e.target.value)} className='form-control find_tower_info'>
                                                    <option value="">Select an option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr class="find_tower_info_no">
                                            <th align="center" valign="middle">6.</th>
                                            <th align="center" valign="middle">Is tower fence avialable ?</th>
                                            <td align="center" valign="middle">
                                                <select value={selected5Option} onChange={(e) => setSelected5Option(e.target.value)} className='form-control find_tower_info'>
                                                    <option value="">Select an option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="tower_information_content">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                        <label class="text-label">Add your comment here</label>
                                        <textarea class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Next</button>
                            <Link to={`../${config.HOME_URL}/dashboard`}  class="btn btn-danger">Back</Link>
                        </div> </>
                    : <Loader />}


            </DashboardLayouts>


        </>
    );
}

export default ListEquipmentTowerDetails;
