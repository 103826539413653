import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProjectDetails from './ProjectDetails';
import DashboardLayouts from '../Layouts/DashboardLayouts';
import Loader from '../Common/Loader';
import config from '../../config';
import close from '../../assets/img/icons8-close-64.png';
import { listTowerEquipmentPhoto } from '../Actions/DataAction';
import BackArrow from '../Common/BackArrow';

const ViewEquipmentTowerGallery = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const item = state?.item;
    const equipment = state?.equipment;
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [inspection, setInspection] = useState(item.inpception_id);
    const [equipmentitem, setEquipmentItem] = useState(equipment.id);
    const [total, setTotal] = useState(0);
    const [resultdata, setResultData] = useState([]);
    
    useEffect(() => {




        const variables = {
          "inspection_id": inspection,
          "equipment_id":equipmentitem
        }
        loadData(variables)
    
      }, []);

      const loadData = (variables) => {

        setIsLoading(true);
        try {
            listTowerEquipmentPhoto(variables).then((res) => {
            console.log(res);
            setResultData(res.data);
            setTotal(res.total);
            setIsLoading(false);
          });
          setErrorMsg('');
        } catch (error) {
          setErrorMsg('Error while loading data. Try again later.');
        }
      }

      const openNavigatePage = (item,inpception) => {
        const itemData = item;
       
        navigate('/step2-equipment-towers-details', { state: { item: itemData } });
    };
    return (
        <>
        <DashboardLayouts>
        
            {isLoading != true  && item ?
                <>
                    <span onClick={(e) => openNavigatePage(item)}><BackArrow/></span>
                    <ProjectDetails dataresult={item} />
                    <div class="row">
                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <div className='page-title'>{equipment.name} ({resultdata.length})</div>
                    </div>
                </div>
                <div class="pending_information_scroll">
                   <div class="row">
                   {resultdata.length > 0 ?  <>
                   {resultdata.length > 0 && resultdata.map((item, i) => {
                        return (
                       <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6 col-6">
                           <div class="tower_image_wrapper">
                               <img src={`${config.STGING_URL}${config.IMAGE_URL}/equipments_inspection/${item.photo_name}`} class="img-fluid" />
                               
                               <a href="javascript:void(0);" class="delete_image"><img src={close} className="img-fluid"/></a>
                           </div>
                       </div>
                        )})}
                        </>:<div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
                           <div class="tower_image_wrapper">
                            <p>No Data Found</p>  
                           </div>
                       </div>}
                       
                       
                   </div>
                </div></>
                    : <Loader />}


            </DashboardLayouts>


        </>
    );
}


export default ViewEquipmentTowerGallery;