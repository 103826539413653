import logo from './logo.svg';

import React, { useRef , useEffect, useState, createContext } from 'react';
import { BrowserRouter } from "react-router-dom";
import Router from './Router';
export const AuthContext = createContext();
function App() {
 

  
 
  
  return (
    <>
        <AuthContext.Provider value={{theme:"dark",name:"Parivesh","sessiondata":"sas"}}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
        </AuthContext.Provider>
    
      
    </>

  );
}

export default App;
