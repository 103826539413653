import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import backarrow from '../../assets/img/arrow.png';
import CONFIG from "../../config";

class BackArrow extends React.Component {
  render() {
    return (
      <>
          <div className="back_arrow">
                  <img src={backarrow} />
                </div>

      </>
    );
  }
}
export default BackArrow;
