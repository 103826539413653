import React from 'react'
import commoncss from '../../assets/css/common.css';

import dashboardcss from '../../assets/css/dashboard.css';
import Topbar from '../Common/Topbar';
const DashboardLayouts =({children}) =>{
    return(
        <>
        <Topbar/>
        <div className="main_body">{children}</div>
        </>
        
    )
}
   
export default DashboardLayouts;