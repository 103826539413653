import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Common/Loader';
import DashboardLayouts from '../Layouts/DashboardLayouts';
import config from '../../config';
import ProjectDetails from './ProjectDetails';
import { getEquipmentListingDetails, saveTowerDetailFinalSubmit } from '../Actions/DataAction';
import BackArrow from '../Common/BackArrow';
import Swal from 'sweetalert2';
import { user_id } from '../Actions/AuthAction';

import addicon from '../../assets/img/add_icon.png';
import viewicon from '../../assets/img/view_icon.png';

const Step2EquipmentTowerDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const item = state?.item;

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [selected1Option, setSelected1Option] = useState("");
    const [selected2Option, setSelected2Option] = useState("");
    const [selected3Option, setSelected3Option] = useState("");
    const [selected4Option, setSelected4Option] = useState("");
    const [selected5Option, setSelected5Option] = useState("");
    const [towerheight, setTowerHeight] = useState(0);

    const [resultdata, setResultData] = useState([]);

    useEffect(() => {




        const variables = {}
        loadData(variables)

    }, []);

    const loadData = (variables) => {

        setIsLoading(true);
        try {
            getEquipmentListingDetails(variables).then((res) => {
                if (res.status) {
                    setResultData(res.data);
                    setIsLoading(false);
                }

            });
            setErrorMsg('');
        } catch (error) {
            setErrorMsg('Error while loading data. Try again later.');
        }
    }



    const saveNavigatePage = (item, equipmentItem) => {
        const itemData = item;
        const equipmentItemData = equipmentItem;
        navigate('/save-equipment-towers-details', { state: { item: itemData, equipment: equipmentItemData } });
    };

    const listNavigatePage = (item, equipmentItem) => {
        const itemData = item;
        const equipmentItemData = equipmentItem;
        navigate('/view-equipment-towers-gallery', { state: { item: itemData, equipment: equipmentItemData } });
    };

    const finalSubmittion = (item) => {

        


        try {


            Swal.fire({
                title: 'Are you sure ?',
                text: 'You are going to submit verification data. And will not be able to make any changes after the submission.',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes, I am sure"
            }).then((result) => {
                if (result.isConfirmed) { 
                    setIsLoading(true);
                        const itemData = item;
                        const variables = {
                            "tid": item.tid,
                            "user_id": user_id()
                        }
                        saveTowerDetailFinalSubmit(variables).then(
                            (res) => {
                                if (res.status) {
                                    navigate('/dashboard');
                                } else {
                                    setIsLoading(false);
                                    setErrorMsg(res.message);
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Something went wrong!',
                                        footer: res.message
                                    })
                                }
                            }
                        )
                    } else {
                        return false;
                    }
                });

        } catch (error) {
            console.log(error);
            // Handle Axios errors here
            if (error.response) {
                // The request was made, but the server responded with a status code outside of the range 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                setErrorMsg('An error occurred while fetching data: ' + error.response.data);
            } else if (error.request) {
                // The request was made, but no response was received
                console.log(error.request);
                setErrorMsg('An error occurred while fetching data: No response received');
            } else {
                // Something else happened while setting up the request that triggered an error
                console.log('Error', error.message);
                setErrorMsg('An error occurred while fetching data: ' + error.message);
            }
        }
    }

    return (
        <>
            <Link to={`../${config.HOME_URL}/dashboard`}><BackArrow /></Link>
            <DashboardLayouts>
                {isLoading != true && item ?
                    <>
                        <ProjectDetails dataresult={item} />
                        <div class="pending_information_scroll">
                            <div className='page-title'>Please fill the following:-</div>
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th align="left" valign="middle" width="50%">Assets List</th>
                                            <th align="center" valign="middle" width="50%" class="text-center">
                                                Action
                                            </th>
                                        </tr>

                                        {resultdata.length > 0 && resultdata.map((equipmentItem, i) => {
                                            return (

                                                <tr>
                                                    <td align="left" valign="middle">{equipmentItem.name}</td>

                                                    <td align="center" valign="middle">
                                                        <button class="btn btn-primary mspace_5" onClick={(e) => saveNavigatePage(item, equipmentItem)}><img src={addicon} height="20px" /></button>
                                                        <button class="btn btn-success mspace_5" onClick={(e) => listNavigatePage(item, equipmentItem)}><img src={viewicon} height="20px" /></button>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>

                            <div className="footer_button">
                                <button type="submit" class="btn btn-primary" onClick={(e) => finalSubmittion(item)}>Final Submit</button>


                            </div>
                        </div> </>
                    : <Loader />}


            </DashboardLayouts>


        </>
    );
}

export default Step2EquipmentTowerDetails;
