import React, { useEffect, useState, useContext } from 'react';
//import { getPageData } from '../Actions/HomeAction';
import Loader from '../Common/Loader';
import { getLogout, fetchDataWithToken, getCircleId, fullname } from '../Actions/AuthAction';
import { getTowerCompletedListingDetails, getTowerListingDetails } from '../Actions/DataAction';
import { Link, NavLink, BrowserRouter, useNavigate } from "react-router-dom";
import { AuthContext } from '../../App';
import config from '../../config';
import DashboardLayouts from '../Layouts/DashboardLayouts';
import PendingTowerList from '../Pages/PendingTowerList';
//import '../../assets/css/dashboard.css';

const Dashboard = (props) => {
  //window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  const navigate = useNavigate();
  const [resultdata, setResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  //const circle = getCircleId();
  const [circle, setCircle] = useState(null);
  const { sessiondata } = useContext(AuthContext);

  const [total, setTotal] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [pending, setPending] = useState(0);

  const [tab1, setTab1] = useState("active");
  const [tab2, setTab2] = useState("");
  



  const logout = (e) => {

    // alert("fsdf");
    if (getLogout(props)) {
      navigate("/");
    }
    //getLogout(props);
  };




  useEffect(() => {




    const variables = {
      "circle_id": getCircleId()
    }
    loadData(variables)

  }, []);



  const loadData = (variables) => {

    setIsLoading(true);
    try {
      getTowerListingDetails(variables).then((res) => {
        console.log(res);
        setResultData(res.data);
        setTotal(res.totalTowers);
        setCompleted(res.completedTowers);
        setPending(res.pendingTowers);
        setIsLoading(false);
      });
      setErrorMsg('');
    } catch (error) {
      setErrorMsg('Error while loading data. Try again later.');
    }
  }

  const loadCompletedData = (variables) => {

    setIsLoading(true);
    try {
      getTowerCompletedListingDetails(variables).then((res) => {
        console.log(res);
        setResultData(res.data);
        setIsLoading(false);
      });
      setErrorMsg('');
    } catch (error) {
      setErrorMsg('Error while loading data. Try again later.');
    }
  }


  const changeTab = (tab) => {
    setResultData([]);
    const variables = {
      "circle_id": getCircleId()
    }

    if(tab == "2"){
      setTab1("");
      setTab2("active");
      loadCompletedData(variables)
    }else{
      setTab1("active");
      setTab2("");
      loadData(variables)
    }
    
    
  }



  return (
    <>
      <DashboardLayouts>
        {isLoading != true ?
          <>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class={`nav-link ${tab1}`} id="pending-tab" onClick={(e) => changeTab(1)} data-bs-toggle="tab" data-bs-target="#pending-tab-pane" type="button" role="tab" aria-controls="pending-tab-pane" aria-selected="false">Pending <br />
                  {pending}</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class={`nav-link ${tab2}`} id="completed-tab" onClick={(e) => changeTab(2)}data-bs-toggle="tab" data-bs-target="#completed-tab-pane" type="button" role="tab" aria-controls="completed-tab-pane" aria-selected="true">Completed <br />
                  {completed}</button>
              </li>



            </ul>
                  <PendingTowerList dataresult={resultdata}/>
              
            
          </>
          : <Loader />}


      </DashboardLayouts>


    </>
  )
}

export default Dashboard;