import React from 'react';
import CONFIG from "../../config";

const ModalPopup = (props) => {

    if (!props) {
        return <div>Loading...</div>;
    }

         
    return (
        <>
        <div className={`modal  `+props.openStatus} id="myModal" >
  <div class="modal-dialog">
    <div class="modal-content">
    {props.children}
     
      

    </div>
  </div>
</div>
        </>
        
    );
};

export default ModalPopup;