import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CONFIG from "./config";
import Dashboard from "./components/Dashboard/Dashboard";
import PendingTowerList from "./components/Pages/PendingTowerList";
import Login from "./components/Auth/Login";
import TowerProgressForm from "./components/Pages/TowerProgressForm";
import Topbar from "./components/Common/Topbar";
import ItemPage from "./components/Pages/ItemPage";
import Step1FillFollowingTowerDetails from "./components/Pages/Step1FillFollowingTowerDetails";
import NotFoundPage from "./components/Pages/NotFoundPage";
import Step2EquipmentTowerDetails from "./components/Pages/Step2EquipmentTowerDetails";
import SaveEquipmentTowerDetails from "./components/Pages/SaveEquipmentTowerDetails";
import ListEquipmentTowerDetails from "./components/Pages/ListEquipmentTowerDetails";
import FinalSubmissionTowerDetails from "./components/Pages/FinalSubmissionTowerDetails";
import ViewEquipmentTowerGallery from "./components/Pages/ViewEquipmentTowerGallery";




export default function Router() {
  const navigate = useNavigate(); // Get the navigate function from useNavigate
  const status = localStorage.getItem("status")
    ? localStorage.getItem("status")
    : false;

    const [token, setToken] = useState(status);

    useEffect(() => {
      if(token){
        navigate("/dashboard");
      }else{
        navigate("/");
      }
   }, []);
    

  return (
    <>
    <React.Fragment>
    
      <Routes>
       
        
        <Route path={`${CONFIG.HOME_URL}`} element={<Login />}></Route>
        <Route path={`${CONFIG.HOME_URL}/dashboard`} element={<Dashboard />}></Route>
        <Route path={`${CONFIG.HOME_URL}/pending-tower-lists`} element={<PendingTowerList />}></Route>
        <Route path={`${CONFIG.HOME_URL}/tower-progress-form/:tid`} element={<TowerProgressForm />}></Route>
        <Route path={`${CONFIG.HOME_URL}/step1-fill-following-towers-details`} element={<Step1FillFollowingTowerDetails />}></Route>
        <Route path={`${CONFIG.HOME_URL}/step2-equipment-towers-details`} element={<Step2EquipmentTowerDetails />}></Route>
        <Route path={`${CONFIG.HOME_URL}/save-equipment-towers-details`} element={<SaveEquipmentTowerDetails />}></Route>
        <Route path={`${CONFIG.HOME_URL}/view-equipment-towers-gallery`} element={<ViewEquipmentTowerGallery />}></Route>
        <Route path={`${CONFIG.HOME_URL}/list-equipment-towers-details`} element={<ListEquipmentTowerDetails />}></Route>
        <Route path={`${CONFIG.HOME_URL}/final-submission-towers-details`} element={<FinalSubmissionTowerDetails />}></Route>
        {/* Catch-all route for 404 errors */}
        <Route element={<NotFoundPage/>} ></Route>
      </Routes>
     
    </React.Fragment>
    
      
    </>
  )
}

