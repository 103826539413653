import axios from "axios";
import CONFIG from "../../config";
import { getAuthHeader } from "../../utils";

// Generate token
// export const getLogin = (model) => {
//     // const model={"id":"-1"}
//     const url = `${CONFIG.BASE_URL}/login`;
//     return axios.post(url, model)
//         .then((response) => {
//           localStorage.setItem("token",response.data.access_token)

//         });
// }

//get state
export const getLogin = (model) => {
  console.log(model);
  const url = `${CONFIG.STGING_URL}/api-auth`;
  return axios.post(url, model).then((response) => {
    if (response.data.status) {
      //alert(response.data.roles[0].role_name);
      localStorage.setItem("status", response.data.status);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("circle_id", response.data.circle_id);
      localStorage.setItem("user_id", response.data.user_id);
      localStorage.setItem("full_name", response.data.full_name);
      localStorage.setItem("usp_circle_name", response.data.circle_name);
      
      //getUserDetails();
    }
    return response.data;
  });
};

// export const fetchDataWithToken = async (token) => {
//   const url = `${CONFIG.STGING_URL}/api-token-details`;
//   try {
//     const response = await axios.get(url, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     });
//     // Handle response data
//     //console.log('Data:', response.data);
//     localStorage.setItem("circle_id",response.data.user.circle_id);
//     localStorage.setItem("full_name",response.data.user.user_full_name);
//     localStorage.setItem("role_id",response.data.user.role_id);
//     localStorage.setItem("user_agency",response.data.user.user_agency);
//     localStorage.setItem("email",response.data.user.email);
//     localStorage.setItem("user_id",response.data.user.user_id);
//     // const jsonString = JSON.stringify(response.data.user);
//     // localStorage.setItem("userdetails",jsonString);
//   } catch (error) {
//     // Handle errors
//     console.error('Error fetching data:', error);
//   }
// };

export const getLogout = (props) => {
  localStorage.removeItem("token");
  localStorage.removeItem("status");
  localStorage.removeItem("userdetails");
  localStorage.removeItem("circle_id");
    localStorage.removeItem("full_name");
    localStorage.removeItem("role_id");
    localStorage.removeItem("user_agency");
    localStorage.removeItem("email");
    localStorage.removeItem("user_id");
    localStorage.removeItem("usp_circle_name");
 

  //history.push("/login");
  //window.location.reload();
  return true;
};

export const fullname = () => {
  return localStorage.getItem("full_name");
};

export const usp_circle_name = () => {
  return localStorage.getItem("usp_circle_name");
};

export const role_id = () => {
  return localStorage.getItem("role_id");
};

export const email = () => {
  return localStorage.getItem("email");
};

export const user_id = () => {
  return localStorage.getItem("user_id");
};
export const getCircleId = () => {
  return localStorage.getItem("circle_id");
};

// export const urlAccess  = () => {{
//     var auth = JSON.parse(localStorage.getItem('token'))
//     if(auth){
//       history.goBack()
//     }else{
//       props.history.push("/");
//     }
// }

// //get state map details
// export const getStateMapDetails = (model) => {
//   const url = `${CONFIG.BASE_URL}/govtinit/1/api/getmapdetails`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get all ministry
// export const selectAllMinistry = (model) => {
//   const url = `${CONFIG.BASE_URL}/master/1/api/getallministry`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get all department
// export const selectAllDepartment = (model) => {
//   const url = `${CONFIG.BASE_URL}/govtinit/1/api/getalldepartment`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get all Central Gov Details
// export const getCentralGovDetails = (model) => {
//   const url = `${CONFIG.BASE_URL}/govtinit/1/api/getsearchcentrelgovdetails`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get single central Gov Details
// export const getSingleCentralGovDetails = (model) => {
//   const url = `${CONFIG.BASE_URL}/govtinit/1/api/getsinglecentrelgovdetails`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get single central Gov Details
// export const getFilterDepartment = (model) => {
//     const url = `${CONFIG.BASE_URL}/govtinit/1/api/selectcentraldepartment`;
//     return axios.post(url, model, getAuthHeader())
//         .then((response) => {
//             return response.data;
//         });
//   }
