import React, { useEffect, useState, useContext } from 'react';
//import { getPageData } from '../Actions/HomeAction';
import Loader from '../Common/Loader';
import { getLogout, fetchDataWithToken, getCircleId, fullname } from '../Actions/AuthAction';

import { Link, NavLink, BrowserRouter, useNavigate } from "react-router-dom";
import { AuthContext } from '../../App';
import TowerProgressForm from './TowerProgressForm';
import DashboardLayouts from '../Layouts/DashboardLayouts';
//import '../../assets/css/dashboard.css';

const PendingTowerList = (props) => {
    //window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const navigate = useNavigate();
    const [resultdata, setResultData] = useState(props.dataresult);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    //const circle = getCircleId();
    const [circle, setCircle] = useState(null);
    const { sessiondata } = useContext(AuthContext);


    const [total, setTotal] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [pending, setPending] = useState(0);
    const [processTower, setProcessTower] = useState(false);
    const [modelPopup, setModelPopup] = useState("hide");
    const [singleTowerDetails, setSingleTowerDetails] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    const logout = (e) => {

        // alert("fsdf");
        if (getLogout(props)) {
            navigate("/");
        }
        //getLogout(props);
    };




    useEffect(() => {




        const variables = {
            "circle_id": getCircleId()
        }
        

    }, []);



    

    const openProcessTower = (item) => {
        console.log(item);
        setProcessTower(true);
        setSingleTowerDetails(item);
        setModelPopup("show");
    };

    const handleClose = () => {
        setProcessTower(false);
        setModelPopup("hide");
    };

    const openNavigatePage = (item) => {
        const itemData = item;
        navigate('/step1-fill-following-towers-details', { state: { item: itemData } });
      };

      const openNavigateEquipmentPage = (item) => {
        const itemData = item;
        navigate('/step2-equipment-towers-details', { state: { item: itemData } });
      };

      const openNavigateCompeltedPage = (item) => {
        const itemData = item;
        navigate('/view-towers-details', { state: { item: itemData } });
      };

      const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const filteredItems = resultdata.filter(item =>

        
        item.tower_id.toLowerCase().includes(searchTerm.toLowerCase())
      );

    const myObject = { id: 123, name: 'Example Item' };
    return (
        <>

            {processTower ? (
                <TowerProgressForm
                    handleClose={handleClose}
                    data={singleTowerDetails}
                    modelOpen={modelPopup}



                />
            ) : (
                ""
            )}
            {isLoading != true ?
                <>
                <div className='mt-3'><input type="text" className='form-control' placeholder='Search By Tower ID'  value={searchTerm}
        onChange={handleSearch}/></div>

                    {filteredItems.length > 0 && filteredItems.map((item, i) => {
                        return (
                            <div class="pending_box">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6 col-6">
                                    <div class="pending_bxtitle">
                                        Tower ID
                                    </div>
                                    <div class="pending_bxtitle">
                                        Status
                                    </div>
                                    {item.physical_inpection_status != 2 ?<><div class="pending_bxtitle">
                                        Action
                                    </div></>:<></>}
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6 col-6">
                                    <div class="pending_bxtitle_text">
                                    {item.tower_id}
                                    </div>
                                    <div class="pending_bxtitle_text">
                                    {item.physical_inpection_status == 2 ?<><span class="badge text-bg-success">Completed</span></>:<> {item.physical_inpection_status == 0 ? <span class="badge text-bg-danger">Pending</span>: <span class="badge text-bg-primary">In-Progress</span>} </>}
                                    </div>
                                    <div class="pending_bxtitle_text">
                                    {item.physical_inpection_status == 2 ?<>
                                        {/* <button class="btn btn-primary start_btn" onClick={(e) => openNavigateCompeltedPage(item)}>View</button> */}
                                    </>
                                    :<>
                                    {item.physical_inpection_status == 0 ?  
                                                <button class="btn btn-primary start_btn" onClick={(e) => openNavigatePage(item)}>Start</button>
                                            :  
                                                <button class="btn btn-primary start_btn" onClick={(e) => openNavigateEquipmentPage(item)}>View</button>
                                            }
                                    </>}
                                            
                                   
                                        

                                    </div>
                                </div>
            
            
            
            
                            </div>
            
                         </div> 
                            
                        )
                    })}
                </>
                : <Loader />}




        </>
    )
}

export default PendingTowerList;