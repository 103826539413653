// validation.js

const validateUsername = (username) => {
    let error = '';
    if (!username) {
      error = 'Username is required';
    }
    // Add more validation rules as needed
    return error;
  };
  
  const validatePassword = (password) => {
    let error = '';
    if (!password) {
      error = 'Password is required';
    } else if (password.length < 6) {
      error = 'Password must be at least 6 characters long';
    }
    // Add more validation rules as needed
    return error;
  };
  
  export { validateUsername, validatePassword };
  