import axios from "axios";
import CONFIG from "../../config";
import { getAuthHeader } from "../../utils";

// Generate token
// export const getLogin = (model) => {
//     // const model={"id":"-1"}
//     const url = `${CONFIG.BASE_URL}/login`;
//     return axios.post(url, model)
//         .then((response) => {
//           localStorage.setItem("token",response.data.access_token)

//         });
// }

//get state
export const getTowerListingDetails = (model) => {
  console.log(model);
  const url = `${CONFIG.STGING_URL}/api-get-towers-listing`;
  return axios.post(url, model).then((response) => {
    return response.data;
  });
};

export const getTowerCompletedListingDetails = (model) => {
  console.log(model);
  const url = `${CONFIG.STGING_URL}/api-get-towers-completed-listing`;
  return axios.post(url, model).then((response) => {
    return response.data;
  });
};



export const getEquipmentListingDetails = (model) => {
  console.log(model);
  const url = `${CONFIG.STGING_URL}/api-equipment-list`;
  return axios.get(url, model).then((response) => {
    return response.data;
  });
};

export const saveTowerEquipmentQuestions = (model) => {
  console.log(model);
  const url = `${CONFIG.STGING_URL}/api-save-tower-inspection-details`;
  return axios.post(url, model).then((response) => {
    return response.data;
  });
};

export const saveTowerDetailFinalSubmit = (model) => {
  console.log(model);
  const url = `${CONFIG.STGING_URL}/api-save-tower-final-submittion-details`;
  return axios.post(url, model).then((response) => {
    return response.data;
  });
};



export const saveTowerEquipmentPhoto = (model) => {
  console.log(model);
  const url = `${CONFIG.STGING_URL}/api-save-tower-inspection-equipment-details`;
  return axios.post(url, model).then((response) => {
    return response.data;
  });
};

export const listTowerEquipmentPhoto = (model) => {
  console.log(model);
  const url = `${CONFIG.STGING_URL}/api-list-tower-inspection-equipment-details`;
  return axios.post(url, model).then((response) => {
    return response.data;
  });
};



// export const urlAccess  = () => {{
//     var auth = JSON.parse(localStorage.getItem('token'))
//     if(auth){
//       history.goBack()
//     }else{
//       props.history.push("/");
//     }
// }

// //get state map details
// export const getStateMapDetails = (model) => {
//   const url = `${CONFIG.BASE_URL}/govtinit/1/api/getmapdetails`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get all ministry
// export const selectAllMinistry = (model) => {
//   const url = `${CONFIG.BASE_URL}/master/1/api/getallministry`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get all department
// export const selectAllDepartment = (model) => {
//   const url = `${CONFIG.BASE_URL}/govtinit/1/api/getalldepartment`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get all Central Gov Details
// export const getCentralGovDetails = (model) => {
//   const url = `${CONFIG.BASE_URL}/govtinit/1/api/getsearchcentrelgovdetails`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get single central Gov Details
// export const getSingleCentralGovDetails = (model) => {
//   const url = `${CONFIG.BASE_URL}/govtinit/1/api/getsinglecentrelgovdetails`;
//   return axios.post(url, model, getAuthHeader())
//       .then((response) => {
//           return response.data;
//       });
// }

// //get single central Gov Details
// export const getFilterDepartment = (model) => {
//     const url = `${CONFIG.BASE_URL}/govtinit/1/api/selectcentraldepartment`;
//     return axios.post(url, model, getAuthHeader())
//         .then((response) => {
//             return response.data;
//         });
//   }
