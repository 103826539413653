import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import loading from '../../assets/img/loading.png';
import CONFIG from "../../config";

class Loader extends React.Component {
  render() {
    return (
      <>
          <div className="overlay">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spinner"></span>
                    <span className="clearfix"></span>
                    <span className="overlay_title">Please wait</span>
                  </div>
              </div>
          </div>

      </>
    );
  }
}
export default Loader;
