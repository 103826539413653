import React, { useState } from 'react';

const ProjectDetails = (props) => {

    const [resultdata, setResultData] = useState(props.dataresult);


    return (
        <>
            <div className="project_header">
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                <span className="project_text_title">{resultdata.project_name}</span>
                <div className="sub_project_title">
                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6 col-6 no_padding_right_0"> <span class="small_text_title small_box_center"><b>Tower ID:</b> <br />{resultdata.tower_id}</span></div>
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6 col-6">
                        <span className="small_text_title small_box_center no_border_right"><b>Circle:</b> <br />{resultdata.circle_name} </span>
                    </div>
                </div>
                </div>
                </div>
            </div>
            </div>
        </>

    );
};

export default ProjectDetails;