import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../Common/Loader';
import DashboardLayouts from '../Layouts/DashboardLayouts';
import config from '../../config';

const NotFoundPage = () => {
    const location = useLocation();
    const { state } = location;
    const item = state?.item;
    const [isLoading, setIsLoading] = useState(false);
    const [selected1Option, setSelected1Option] = useState("");
    const [selected2Option, setSelected2Option] = useState("");
    const [selected3Option, setSelected3Option] = useState("");
    const [selected4Option, setSelected4Option] = useState("");
    const [selected5Option, setSelected5Option] = useState("");
    const [towerheight, setTowerHeight] = useState(0);

    return (
        <>
            <DashboardLayouts>
                {isLoading != true && item ?
                <><h1>404 Not found</h1></>
                    : <Loader />}


            </DashboardLayouts>


        </>
    );
}

export default NotFoundPage;
