import React, { useEffect, useState } from 'react';
import { Link, NavLink, BrowserRouter, useNavigate, redirect } from "react-router-dom";
//import { getPageData } from '../Actions/HomeAction';
import Loader from '../Common/Loader';

import logo from '../../assets/img/usof_logo.png';
import emblem_logo from '../../assets/img/emblem_logo.png';
import { fetchDataWithToken, getLogin } from "../Actions/AuthAction";
import { validateLoginForm, validateRegistrationForm } from "../Common/validation";
import { validateUsername, validatePassword } from "../Common/SingleValidation";
import LoginLayouts from '../Layouts/LoginLayouts';

const Login = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const [resultdata, setResultData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate(); // Get the navigate function from useNavigate


    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setUsername(value);
        setUsernameError(validateUsername(value));
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        setPasswordError(validatePassword(value));

    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const usernameError = validateUsername(username);
        const passwordError = validatePassword(password);
        setUsernameError(usernameError);
        setPasswordError(passwordError);
        if (!usernameError && !passwordError) {
            try {
                getLogin({ username: username, password: password }).then(
                    (res) => {
                        if (res.status) {

                            navigate("/dashboard");
                        } else {
                            setIsLoading(false);
                            setErrorMsg(res.message);
                        }


                    }
                )
            } catch (error) {
                console.log(error);
                // Handle Axios errors here
                if (error.response) {
                    // The request was made, but the server responded with a status code outside of the range 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    setErrorMsg('An error occurred while fetching data: ' + error.response.data);
                } else if (error.request) {
                    // The request was made, but no response was received
                    console.log(error.request);
                    setErrorMsg('An error occurred while fetching data: No response received');
                } else {
                    // Something else happened while setting up the request that triggered an error
                    console.log('Error', error.message);
                    setErrorMsg('An error occurred while fetching data: ' + error.message);
                }
            }
        } else {
            setIsLoading(false);
        }
    }

    return (
        <>
            {isLoading != true ? <></> : <Loader />}
            <LoginLayouts>
                <div className="login-wrap">
                    <div className="login-html">
                        <div className="login-form">

                            <div className="sign-in-htm">
                            <h1 className="login_title">Mobile Tower Verification App</h1>
                                <div className="login_header">
                                    <span><img src={emblem_logo} className="logo_border" /></span>
                                    
                                    <span><img src={logo} /></span>
                                </div>
                                
                                <div class="group">
                                    <label for="user" class="label">Username</label>
                                    <input
                                        className='input'
                                        type="text"
                                        value={username}
                                        onChange={handleUsernameChange}
                                    />
                                    {usernameError && <p className="error">* {usernameError}</p>}
                                </div>
                                <div className="group">
                                    <label for="pass" className="label">Password</label>
                                    <input
                                        className='input'
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                    {passwordError && <p className="error">* {passwordError}</p>}
                                </div>

                                <div className="group">
                                    {errorMsg != null ? <>
                                        <div class="alert alert-danger">
                                            <strong>Error!</strong> {errorMsg}
                                        </div>
                                    </> : ''}
                                    <button type="button" className='btn btn-primary button' onClick={(e) => handleLogin(e)}>Login</button>

                                </div>
                                <div class="hr"></div>

                                <div class="foot-lnk">
                                    <a href="#forgot">Forgot Password?</a>
                                       <div className="clearfix"></div>
                                 <a href="javascript:void(0);" className="powered_by_text">Powered by USOF</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </LoginLayouts>

        </>
    )
}

export default Login;