import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import backarrow from '../../assets/img/arrow.png';
import CONFIG from "../../config";

class PageTitle extends React.Component {
  render() {
    return (
      <>
          <span className="header_title">Dashboard</span>

      </>
    );
  }
}
export default PageTitle;
