import React, { useEffect, useState, useContext } from 'react';
//import { getPageData } from '../Actions/HomeAction';
import Loader from '../Common/Loader';
import { getLogout, fetchDataWithToken, getCircleId, fullname } from '../Actions/AuthAction';
import { getTowerListingDetails } from '../Actions/DataAction';
import { Link, NavLink, BrowserRouter, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from '../../App';
import DashboardLayouts from '../Layouts/DashboardLayouts';
import ModalPopup from '../Common/ModalPopup';

import '../../assets/css/switch.css';

const TowerProgressForm = (props) => {
    //window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    console.log({ props });
    const navigate = useNavigate();
    const tid = useParams().tid;
    const [resultdata, setResultData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    //const circle = getCircleId();
    const [circle, setCircle] = useState(null);
    const { sessiondata } = useContext(AuthContext);


    const [total, setTotal] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [pending, setPending] = useState(0);

    const [switch1Checked, setSwitch1Checked] = useState(false);
    const [switch2Checked, setSwitch2Checked] = useState(false);
    const [switch3Checked, setSwitch3Checked] = useState(false);
    const [switch4Checked, setSwitch4Checked] = useState(false);
    const [switch5Checked, setSwitch5Checked] = useState(false);
    const [selected1Option, setSelected1Option] = useState("");
    const [selected2Option, setSelected2Option] = useState("");
    const [selected3Option, setSelected3Option] = useState("");
    const [selected4Option, setSelected4Option] = useState("");
    const [selected5Option, setSelected5Option] = useState("");
    
    const [towerheight, setTowerHeight] = useState(0);
    const handleTowerHeight = (e) => {
        const value = e.target.value;
        setTowerHeight(value);
        //setUsernameError(validateUsername(value));
    };
    const [venuestatus, setvenuestatus] = useState(false);

    // const handleSwitch1Change = () => {
    //     setSwitch1Checked(!switch1Checked);
    // };

    // const handleSwitch2Change = () => {
    //     setSwitch2Checked(!switch2Checked);
    // };

    // const handleSwitch3Change = () => {
    //     setSwitch3Checked(!switch3Checked);
    // };

    // const handleSwitch4Change = () => {
    //     setSwitch4Checked(!switch4Checked);
    // };

    // const handleSwitch5Change = () => {
    //     setSwitch5Checked(!switch5Checked);
    // };



    useEffect(() => {





    }, []);







    return (
        <>
            <ModalPopup openStatus={props.modelOpen}>
                

                <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                    <span class="project_text_title"><b>Project:</b> {props.data.project_id}</span>
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12"> <span class="small_text_title"><b>Tower ID:</b> {props.data.tower_id}</span></div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <span class="small_text_title"><b>Circle:</b> {props.data.circle_id} </span>
                        </div>
                    </div>



                </h1>
            </div>


                <div class="modal-body">


                <div class="row">
                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <b>Please fill the following:-</b>
                    </div>
                </div>
                <div class="pending_information_scroll">
                    <div class="table-responsive">
                      <table class="table">
                        <tbody>
                        <tr>
                            <th align="center" valign="middle" width="5%">1.</th>
                            <th align="center" valign="middle" width="60%">Did you find the tower ?</th>
                            <td align="center" valign="middle" width="25%">
                                
                                <select value={selected1Option} onChange={(e) => setSelected1Option(e.target.value)} className='form-control find_tower_info'>
                                                <option value="">Select an option</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                            </td>
                        </tr>
                        <tr class="find_tower_info_no">
                            <th align="center" valign="middle">2.</th>
                            <th align="center" valign="middle">Is tower commissioned ?</th>
                            <td align="center" valign="middle">
                            <select value={selected2Option} onChange={(e) => setSelected2Option(e.target.value)} className='form-control find_tower_info'>
                                                <option value="">Select an option</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                            </td>
                        </tr>
                        <tr class="find_tower_info_no">
                            <th align="center" valign="middle">3.</th>
                            <th align="center" valign="middle">Is construction is process ?</th>
                            <td align="center" valign="middle">
                            <select value={selected3Option} onChange={(e) => setSelected3Option(e.target.value)} className='form-control find_tower_info'>
                                                <option value="">Select an option</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                            </td>
                        </tr>
                        <tr class="find_tower_info_no">
                            <th align="center" valign="middle">4.</th>
                            <th align="center" valign="middle">Tower height (meter)</th>
                            <td align="center" valign="middle">
                                <input type="text" class="form-control" value="0"/>
                            </td>
                        </tr>
                        <tr class="find_tower_info_no">
                            <th align="center" valign="middle">5.</th>
                            <th align="center" valign="middle">Is tower up &amp; running ?</th>
                            <td align="center" valign="middle">
                            <select value={selected4Option} onChange={(e) => setSelected4Option(e.target.value)} className='form-control find_tower_info'>
                                                <option value="">Select an option</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                            </td>
                        </tr>
                        <tr class="find_tower_info_no">
                            <th align="center" valign="middle">6.</th>
                            <th align="center" valign="middle">Is tower fence avialable ?</th>
                            <td align="center" valign="middle">
                            <select value={selected5Option} onChange={(e) => setSelected5Option(e.target.value)} className='form-control find_tower_info'>
                                                <option value="">Select an option</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>

                    <div class="tower_information_content">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                <label class="text-label">Add your comment here</label>
                                <textarea class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                    
                </div>


                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">Next</button>
                    <button type="button" class="btn btn-danger" onClick={props.handleClose}>Close</button>
                </div>

            </ModalPopup>




        </>
    )
}

export default TowerProgressForm;