import React, { useEffect, useState, useContext } from 'react';
//import { getPageData } from '../Actions/HomeAction';
import Loader from '../Common/Loader';
import { getLogout, fetchDataWithToken, getCircleId, fullname,usp_circle_name } from '../Actions/AuthAction';

import { Link, NavLink, BrowserRouter, useNavigate } from "react-router-dom";
import { AuthContext } from '../../App';
import config from '../../config';
import Dashboard from '../Dashboard/Dashboard';

import backarrow from '../../assets/img/arrow.png';
import PageTitle from '../Common/PageTitle';

const Topbar = (props) => {
    //window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const navigate = useNavigate();
    const [resultdata, setResultData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    //const circle = getCircleId();
    const [circle, setCircle] = useState(null);
    const { sessiondata } = useContext(AuthContext);

    const [total, setTotal] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [pending, setPending] = useState(0);
    const [isHighlighted, setIsHighlighted] = useState(false);




    const logout = (e) => {

        // alert("fsdf");
        if (getLogout(props)) {
            navigate("/");
            //window.location.reload();
        }
        //getLogout(props);
    };



    const toggleClassName = (e) => {
        setIsHighlighted(!isHighlighted);
    };


    const styles = {
        displayhide: 'mobile-menu hide',
        displayshow: 'mobile-menu active',
        leftsidehide: 'leftsidehide'
    };



    return (
        <>

            <div class="leftside_bar" className={styles.leftsidehide}>
                Leftside bar
            </div>
            <div class="header shadow">
                
                <div class="hamburger-menu" onClick={toggleClassName}>
                    <div class="bar"></div>
                </div>
                <nav className={isHighlighted ? styles.displayshow : styles.displayhide}
                >

                    <ul>
                        <li>{fullname()} <br/><span>({usp_circle_name()})</span></li>
                        <li><Link to={`../${config.HOME_URL}/dashboard`}>Dashboard</Link></li>
                        <li><a href="blog.html">Change Password</a></li>
                        <li>
                            <a
                                href="javascript:void(0)"
                                onClick={logout}
                            >
                                Logout
                            </a></li>
                    </ul>
                </nav>
                 <PageTitle />
            </div>



        </>
    )
}

export default Topbar;