export default {
  //api manger api access url

  STGING_URL: "https://staging.19216811.org.in",
  //STGING_URL: "http://localhost:8181/gitpmis/usofpmis/public",
  
  
  //DOMAIN_URL: "http://localhost:8181/jwtloginyii/",
  //IMAGE_URL: "http://localhost:8181/jwtloginyii/upload/",

  //build time
  //HOME_URL:"parivesh_new",
  //IMAGE_URL:"https://stgdev.parivesh.nic.in/parivesh_new/",
  

  //localhost
  HOME_URL:"",
  IMAGE_URL:"/assets/uploads/",

  //BASE_URL: "https://akishitsolutions.in/geekapi/web/index.php",
  //DOMAIN_URL: "https://akishitsolutions.in/geekapi/",
  //IMAGE_URL: "https://akishitsolutions.in/geekapi//upload/",

  //without api manager and external url access url
  //DIRECT_BASE_URL:"https://stgstatic.digitalsmemitra.in/sidbifiles",

  //GENERATE_TOKEN_URL:"http://192.168.10.254",
  //GENERATE_TOKEN_URL:"https://stgapi.digitalsmemitra.in",

  DEBUG: true,
  ENV: "stage",

  //TOKEN:localStorage.getItem("token")
  // GENERATE_TOKEN_URL: "http://103.82.220.59:81",
  // BASE_URL: "http://192.168.10.254",
  // TOKEN:"eyJ4NXQiOiJNMlEyTm1Fek56RmxNV1JrT0RReVptRTRZalV5TnpBd00ySTJPRGt4WVdObE9USXpZakV4WmpCaFlXWmhNREl4WTJFek9HRmtaVE5oTVdZeE1UQTBZUSIsImtpZCI6Ik0yUTJObUV6TnpGbE1XUmtPRFF5Wm1FNFlqVXlOekF3TTJJMk9Ea3hZV05sT1RJellqRXhaakJoWVdaaE1ESXhZMkV6T0dGa1pUTmhNV1l4TVRBMFlRX1JTMjU2IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJhZG1pbkBjYXJib24uc3VwZXIiLCJhdXQiOiJBUFBMSUNBVElPTiIsImF1ZCI6ImhZUXAxU0tnMFRZdmlQalEzSkl5OFgyakJ2Y2EiLCJuYmYiOjE2MTY0MTIxNzgsImF6cCI6ImhZUXAxU0tnMFRZdmlQalEzSkl5OFgyakJ2Y2EiLCJzY29wZSI6ImFtX2FwcGxpY2F0aW9uX3Njb3BlIGRlZmF1bHQiLCJpc3MiOiJodHRwczpcL1wvbG9jYWxob3N0Ojk0NDNcL29hdXRoMlwvdG9rZW4iLCJleHAiOjE2MTY0MTU3NzgsImlhdCI6MTYxNjQxMjE3OCwianRpIjoiZTE2YmUwZDgtOGY3NC00MTYzLTg5YTItMzdmOTk5Y2Y0YzE5In0.Vpz-1c6jDEPZm7_Si9cAnsUuIDH_TCoGxOZhYFTwx-l6bumzEM0Aia_tikwyigqqB3mvEAEiMWn2h1eITqGE5xdKxA235HFHyFMbOw4pCAhYuxBtLF9_PzxNpNVC7InjE7_JNEwiciwhqd8VVj9dz-VEbf_t1QHKOB6xyKS5iK7XUEQ6S9_mMVRKh0suVug1QKsF8UymcpaeJnnLvvnX0R_011VW6AcKUzpHfb3UJjZOxgfGKMSg3G6bK3rb1eJs0FW6dDIEdgG0vdt5hwgILSqRxskzBA8td5wulY282LcreDICfOFmiF_7Ja-JH0FTU7df6ypuJtKiMJifcRERAA"
};
