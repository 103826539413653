import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Common/Loader';
import DashboardLayouts from '../Layouts/DashboardLayouts';
import config from '../../config';
import ProjectDetails from './ProjectDetails';
import camera from '../../assets/img/camera.png';
import clickcamera from '../../assets/img/clickcamera.png';
import gallery from '../../assets/img/gallery.png';
import camerawhite from '../../assets/img/icons8-camera-50.png';
import close from '../../assets/img/icons8-close-64.png';
import nopreview from '../../assets/img/no-preview.jpeg';
import Webcam from 'react-webcam';
import ModalPopup from '../Common/ModalPopup';
import { user_id } from '../Actions/AuthAction';
import { saveTowerEquipmentPhoto } from '../Actions/DataAction';
import Swal from 'sweetalert2';
import BackArrow from '../Common/BackArrow';

const SaveEquipmentTowerDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const item = state?.item;
    const equipment = state?.equipment;
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const [inpception_id, setInception_id] = useState(item.inpception_id);

    const [backCameraId, setBackCameraId] = useState(null);

    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

  const videoConstraints = {
    width: { min: 1280 },
    height: { min: 720 },
    facingMode: isMobile ? 'environment' : 'user' // Use 'environment' (back camera) on mobile, 'user' (front camera) on desktop
  };

  useEffect(() => {
    

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                (error) => {
                    setError(error.message);
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    getLocation();
  }, []);

    const [imagepath, setImagepath] = useState(null);
    const [mirrored, setMirrored] = useState(false);
    const [modelPopup, setModelPopup] = useState("hide");
    const webRef = useRef(null);

    const showImage = () => {
        console.log(webRef.current.getScreenshot());
        setImagepath(webRef.current.getScreenshot());
        setMirrored(false);
    }

    const openCam = () => {
        setMirrored(true);
    }

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
       

        if (!selectedFile) {
            console.log("Please select a file.");
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
            const base64String = reader.result;
            // Send the base64String to your server using an HTTP request (e.g., fetch or axios)
            console.log(base64String); // Placeholder for sending the base64 string to the server

            const base64WithPrefix = base64String.replace(/^data:(.*;base64,)?/, 'data:image/jpeg;base64,');
            
            setImagepath(base64WithPrefix);
        };
        reader.onerror = (error) => {
            console.error("File reading error:", error);
        };
    };

    

    const openNavigatePage = (item,inpception) => {
        const itemData = item;
       
        navigate('/step2-equipment-towers-details', { state: { item: itemData } });
    };

    const saveTowerEquipmentDetails =  () => {

        setIsLoading(true);


        try {
            if(imagepath == null){
                Swal.fire({
                    icon: 'error',
                    title: 'Validation',
                    text: 'Please click the capture the image.'
                });
                setIsLoading(false);
                return false;
            }
           
            const variables = {
                    "inspection_id":inpception_id,
                    "equipment_id":equipment.id,
                    "is_available":"yes",
                    "photo_datasrc":imagepath,
                    "lat":latitude,
                    "lng":longitude,
                    "user_id":user_id()
                }


                saveTowerEquipmentPhoto(variables).then(
                (res) => {
                    if (res.status) {

                        Swal.fire({
                            icon: 'success',
                            title: 'Saved',
                            footer: res.message
                          })
                          setIsLoading(false);
                          
                          const itemData = item;
                          navigate('/view-equipment-towers-gallery', { state: { item: itemData,equipment: equipment} });
                    } else {
                        setIsLoading(false);
                        setErrorMsg(res.message);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            footer: res.message
                          })
                    }


                }
            )
        } catch (error) {
            console.log(error);
            // Handle Axios errors here
            if (error.response) {
                // The request was made, but the server responded with a status code outside of the range 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                setErrorMsg('An error occurred while fetching data: ' + error.response.data);
            } else if (error.request) {
                // The request was made, but no response was received
                console.log(error.request);
                setErrorMsg('An error occurred while fetching data: No response received');
            } else {
                // Something else happened while setting up the request that triggered an error
                console.log('Error', error.message);
                setErrorMsg('An error occurred while fetching data: ' + error.message);
            }
        }

        
        
    };

    const openCamera = () => {
        setMirrored(true);
        setModelPopup("show");
    };

    const handleClose = () => {
        setMirrored(false);
        setModelPopup("hide");
    };







    const renderModelPop = () => {
        return (
          <>
          
          <div class="modal show" id="myModal">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">

      

      <div class="modal-body">
        <div className="webcam-container">
        <Webcam
            ref={webRef}
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          className="webcam-video"
          
        />
        
        
            
          <img
                                                    className="zmdi-hc-spin"
                                                    src={camerawhite}
                                                    width="48"
                                                    height="auto"
                                                    alt=" "
                                                    onClick={(e) => showImage()} style={{ 
                                                        position: 'absolute', 
                                                        bottom: '10px', 
                                                        left: '50%', 
                                                        transform: 'translateX(-50%)',
                                                        zIndex: 1
                                                      }}

                                                />
                                                <img
                                                    className="zmdi-hc-spin"
                                                    src={close}
                                                    width="32"
                                                    height="auto"
                                                    alt=" "
                                                    onClick={(e) => handleClose()} 
                                                    style={{ 
                                                        position: 'absolute', 
                                                        top: '20px', 
                                                        right: '20px',
                                                        zIndex: 1
                                                      }}

                                                />
          
        
            </div> 
      </div>


    </div>
  </div>
</div>
            
          </>
    );
  };

    return (
        <>
        
            <DashboardLayouts>
            {mirrored ? renderModelPop() : <></>}
                {isLoading != true && item ?
                    <>
                 
                        <ProjectDetails dataresult={item} />

                        <div class="pending_information_scroll">

                            <div className='page-title'>{equipment.name}</div>
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        
                                        <tr>

                                            <th align="center" valign="middle">Click to Capture the image :</th>
                                            <td align="center" valign="middle">
                                                <span class="camera_icon" onClick={(e) => openCamera()}><img
                                                    className="zmdi-hc-spin"
                                                    src={clickcamera}
                                                    width="32"
                                                    height="auto"
                                                    alt=" "
                                                    style={{ cursor: 'pointer',border:'0px' }}
                                                /></span>
        <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="fileInput" />
                <label htmlFor="fileInput">
                    <img
                                                    className="zmdi-hc-spin"
                                                    src={gallery}
                                                    width="32"
                                                    height="auto"
                                                    alt=" "
                                                    style={{ cursor: 'pointer' }}
                                                />
                </label>
                                            </td>
                                        </tr>



                                    </tbody>
                                </table>
                                <div className="image_wrpper">
                            {imagepath ?<>
                                <img src={imagepath}/>
                                {error ? (
                                    <p>Error: {error}</p>
                                ) : (
                                    <div className="latlng-wrapper">
                                        <p>Latitude: {latitude}</p>
                                        <p> Longitude: {longitude}</p>
                                    </div>
                                )}
                                </>:<><img src={nopreview} /></>}
                                </div>
                            </div>
                            <div className="footer_button">
                             <button type="submit" class="btn btn-primary" onClick={saveTowerEquipmentDetails}>Save</button>
                             
                            </div>
                            <span onClick={(e) => openNavigatePage(item)}><BackArrow/></span>
                        </div> </>
                    : <Loader />}


            </DashboardLayouts>


        </>
    );
}

export default SaveEquipmentTowerDetails;
