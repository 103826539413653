import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Common/Loader';
import DashboardLayouts from '../Layouts/DashboardLayouts';
import config from '../../config';
import ProjectDetails from './ProjectDetails';
import Swal from 'sweetalert2';
import { saveTowerEquipmentQuestions } from '../Actions/DataAction';
import { user_id } from '../Actions/AuthAction';
import BackArrow from '../Common/BackArrow';

const Step1FillFollowingTowerDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const item = state?.item;
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [selected1Option, setSelected1Option] = useState("");
    const [selected2Option, setSelected2Option] = useState("");
    const [selected3Option, setSelected3Option] = useState("");
    const [selected4Option, setSelected4Option] = useState("");
    const [selected5Option, setSelected5Option] = useState("");
    const [selected6Option, setSelected6Option] = useState("");
    const [towerheight, setTowerHeight] = useState(0);
    const [comment, setComment] = useState("");
    const [locationCheck, setLocationCheck] = useState("yes");
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    useEffect(() => {
    

        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLatitude(position.coords.latitude);
                        setLongitude(position.coords.longitude);
                    },
                    (error) => {
                        setErrorMsg(error.message);
                    }
                );
            } else {
                setErrorMsg('Geolocation is not supported by this browser.');
            }
        };
    
        getLocation();
      }, []);

    const openNavigatePage =  (item) => {

        


        try {

            if(selected6Option == ""){
                Swal.fire({
                    icon: 'error',
                    title: 'Validation',
                    text: 'Please choose "Did you find the Location ?"'
                });
                return false;
            }

            var textData = "";
            if(selected6Option == "yes"){
                textData = "You are going to submit data.";
            }else{
                textData = "You are going to submit verification data. And will not be able to make any changes after the submission.";
            }

            Swal.fire({
                title: 'Are you sure ?',
                text: textData,
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes, I am sure"
            }).then((result) => {
                if (result.isConfirmed) {    
                    setIsLoading(true);
                    const itemData = item;
                    const variables = {
                            "tid":item.tid,
                            "question_find_location":selected6Option,
                            "question_find_tower":selected1Option,
                            "question_commissioned_tower":selected2Option,
                            "question_construction_is_process":selected3Option,
                            "question_tower_height":towerheight,
                            "question_tower_up_and_running":selected4Option,
                            "question_tower_fence_status":selected5Option,
                            "comment":comment,
                            "lat":latitude,
                            "lng":longitude,
                            "user_id":user_id()
                        }


                    saveTowerEquipmentQuestions(variables).then(
                        (res) => {
                            if (res.status) {

                                if(selected6Option == "yes"){
                                    itemData.inpception_id = res.inpception_id;
                                    navigate('/step2-equipment-towers-details', { state: { item: itemData } });
                                }else{
                                    navigate('/dashboard');
                                }
                                
                                
                            } else {
                                setIsLoading(false);
                                setErrorMsg(res.message);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Something went wrong!',
                                    footer: res.message
                                })
                            }


                        }
                    )
                } else {
                    return false;
                }
            });
        } catch (error) {
            console.log(error);
            // Handle Axios errors here
            if (error.response) {
                // The request was made, but the server responded with a status code outside of the range 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                setErrorMsg('An error occurred while fetching data: ' + error.response.data);
            } else if (error.request) {
                // The request was made, but no response was received
                console.log(error.request);
                setErrorMsg('An error occurred while fetching data: No response received');
            } else {
                // Something else happened while setting up the request that triggered an error
                console.log('Error', error.message);
                setErrorMsg('An error occurred while fetching data: ' + error.message);
            }
        }

        
        
    };

    const openFinalSubmitWithoutLocation = (item) => {
        const itemData = item;
    };

    const handleLocatioCheck = (e) => {
        
        const value = e.target.value;
        setSelected6Option(value);
        setLocationCheck(value)

    };

    

    return (
        <>
            <DashboardLayouts>
                {isLoading != true && item ?
                    <>
                    <Link to={`../${config.HOME_URL}/dashboard`}><BackArrow/></Link>
                    
                        <ProjectDetails dataresult={item} />
                        <div class="pending_information_scroll">
                           
                            <div className='page-title'>Please fill the following:-</div>

                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th align="center" valign="middle" width="5%">1.</th>
                                            <th align="center" valign="middle" width="60%">Did you find the Location ?</th>
                                            <td align="center" valign="middle" width="25%">

                                                <select value={selected6Option} onChange={handleLocatioCheck} className='form-control find_tower_info'>
                                                    <option value="">Select</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            </td>
                                        </tr>
                                        {locationCheck != "no" ? <><tr>
                                            <th align="center" valign="middle" width="5%">2.</th>
                                            <th align="center" valign="middle" width="60%">Did you find the tower ?</th>
                                            <td align="center" valign="middle" width="25%">

                                                <select value={selected1Option} onChange={(e) => setSelected1Option(e.target.value)} className='form-control find_tower_info'>
                                                    <option value="">Select</option>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            </td>
                                        </tr>
                                            <tr class="find_tower_info_no">
                                                <th align="center" valign="middle">3.</th>
                                                <th align="center" valign="middle">Is tower commissioned ?</th>
                                                <td align="center" valign="middle">
                                                    <select value={selected2Option} onChange={(e) => setSelected2Option(e.target.value)} className='form-control find_tower_info'>
                                                        <option value="">Select</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr class="find_tower_info_no">
                                                <th align="center" valign="middle">4.</th>
                                                <th align="center" valign="middle">Is construction is process ?</th>
                                                <td align="center" valign="middle">
                                                    <select value={selected3Option} onChange={(e) => setSelected3Option(e.target.value)} className='form-control find_tower_info'>
                                                        <option value="">Select</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr class="find_tower_info_no">
                                                <th align="center" valign="middle">5.</th>
                                                <th align="center" valign="middle">Width of the tower base (Meter)</th>
                                                <td align="center" valign="middle">
                                                    <input type="number" class="form-control" value={towerheight} onChange={(e) => setTowerHeight(e.target.value)} />
                                                </td>
                                            </tr>
                                            
                                            <tr class="find_tower_info_no">
                                                <th align="center" valign="middle">6.</th>
                                                <th align="center" valign="middle">Is tower up &amp; running ?</th>
                                                <td align="center" valign="middle">
                                                    <select value={selected4Option} onChange={(e) => setSelected4Option(e.target.value)} className='form-control find_tower_info'>
                                                        <option value="">Select</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr class="find_tower_info_no">
                                                <th align="center" valign="middle">7.</th>
                                                <th align="center" valign="middle">Is tower fence avialable ?</th>
                                                <td align="center" valign="middle">
                                                    <select value={selected5Option} onChange={(e) => setSelected5Option(e.target.value)} className='form-control find_tower_info'>
                                                        <option value="">Select</option>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </select>
                                                </td>
                                            </tr></> : <><tr class="find_tower_info_no">
                                                <th align="center" valign="middle">2.</th>
                                                <td align="" valign="middle" colSpan={2}>
                                                    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                                        <label class="text-label">Add your comment here</label>
                                                        <textarea class="form-control" onChange={(e) => setComment(e.target.value)}>{comment}</textarea>
                                                    </div>
                                                </td>
                                            </tr></>}
                                    </tbody>
                                </table>
                            </div>

                            <div className="footer_button">
                            {locationCheck != "no" ? <button class="btn btn-primary" onClick={(e) => openNavigatePage(item)}>Next</button>:<button class="btn btn-primary" onClick={(e) => openNavigatePage(item)}>Submit</button>}


                            </div>
                           
                            
                        </div> </>
                    : <Loader />}


            </DashboardLayouts>


        </>
    );
}

export default Step1FillFollowingTowerDetails;
